import React, { useEffect, useState } from 'react';
import { useParams } from '@reach/router';
import Slider from "react-slick";

import MainLayout from '../../layouts/mainlayout/MainLayout';
import PageHeader from '../../components/pageheader/PageHeader';
import { BASE_URL } from "../../utils/constants";

import './events.scss';

export default function EventTemplate() {
	const { slug } = useParams();
	const [event, setEvent] = useState({});

	useEffect(() => {
		console.log(slug);
		fetch(`${BASE_URL}/api/open/events/${slug}`)
			.then(response => response.json())
			.then(data => {
				console.log("resp", data);
				setEvent(data.Data);
			})
	}, [slug])

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1450,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: true
				}
			},
			{
				breakpoint: 850,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

	return (
		<MainLayout title={"title"}>
			<PageHeader title="Events" subnav='events' subtitle={event.title} />
			<div className='announcement-layout'>
				<h1 className="announcement-head mt-3">{event.title}</h1>
				<div className="date">{event.date ? new Date(event.date).toDateString() : ""}</div>
				<div className="gallery-slider">
					<Slider {...settings}>
						{
							event.galleryImgs ? event.galleryImgs.map(data => {
								return (
									<div className='image-holder' >
										<img src={data} />
									</div>
								)
							})
								:
								""
						}
					</Slider>
				</div>
				<div className="body mt-5" dangerouslySetInnerHTML={{ __html: event.html }} />
			</div>
		</MainLayout>
	);
}
